

import { ModelSelect, ModelListSelect  } from 'vue-search-select'
import 'vue-search-select/dist/VueSearchSelect.css'

// import UMUM from         '../../library/umum.js'
import UMUM from         '../../library/umum'
import DATA_MASTER from  '../../library/dataMaster'

export default {
  components: {
      ModelSelect, ModelListSelect, 
  },

  data() {
    return {
      

    form : {
        id : '',
        title : '',
        icon : '',
        route : '',
        type : 0,
        jenis : 1,
        parrent : null,
        urutan : 0,
        color : ''
    },
    
      list_data : [],
    
      UMUM : UMUM,
      DATA_MASTER : DATA_MASTER,

      page_first: 1,
      page_last: 0,
      page_limit : 10,
      cari_value: "",

      cek_load_data : true,


      mdl_add: false,
      mdl_edit: false,
      mdl_remove : false,
     
    }
  },
  methods: {


    getView : function(){

        this.cek_load_data = true;
      fetch(this.$store.state.url.URL_DM_MENU + "view", {
            method: "POST",
            headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
                cari_value: ''
            })
        })
            .then(res => res.json())
            .then(res_data => {
              console.log(res_data)
              this.cek_load_data = false;
                this.list_data = res_data;
        });
    },

    addData : function(number) {
      fetch(this.$store.state.url.URL_DM_MENU + "addData", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify(this.form)
        }).then(res_data => {
            this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
            this.getView();
        });
      // console.log(this.form.jeniskategorilokasi)
    },

    editData : function(){
        fetch(this.$store.state.url.URL_DM_MENU + "editData", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify(this.form)
        }).then(res_data => {

            this.getView();
            this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
        });
    },

    removeData : function(E){

        fetch(this.$store.state.url.URL_DM_MENU + "removeData", {
            method: "POST",
            headers: {
                "content-type": "application/json",
                authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify(this.form)
        }).then(res_data => {
            this.getView();
            this.mdl_remove = false;
            this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
        });

    },

    selectData : function(data){
        this.form.id = data.id;
        this.form.title = data.title;
        this.form.icon = data.icon;
        this.form.route = data.route;
        this.form.type = data.type;
        this.form.jenis = data.jenis;
        this.form.parrent = data.parrent;
        this.form.urutan = data.urutan;
        this.form.color = data.color;
    
    },

    // ====================================== PAGINATE ====================================
        Notify : function(message, positive, icon){
          this.$q.notify({
            message: message,
            color: positive,
            icon: icon,
            position : 'top',
            timeout: 500,
          })
        },

        indexing : function(index){
            var idx = ((this.page_first-1)*this.page_limit)+index
            return idx;
        },



        cari_data : function(){
            this.page_first = 1;
            this.getView();
        },

        showHide(data){
            var element = document.getElementById(data);
            element.classList.toggle("showx");
        },
        renameType(data){
            if (data == 0) {
            return 'Single Menu'
            } else {
            return 'Multy Menu'
            }
        },
        checkType(data){
            if (data == 0) {
            return true
            } else {
            return false
            }
        },

        checkLength(data){
            if (data.length <= 0) {
            return true
            } else {
            return false
            }
        },




    // ====================================== PAGINATE ====================================
  },

  mounted () {

    this.getView();

  },
}
